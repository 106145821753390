@if (item) {
<div class="single-item full">
  <h6 class="item-title">
    <span class="calendar">{{item.day}}. {{item.month | translit}}.</span>
    <span class="item-name">{{ item.title | translit }}</span>
    @if (authService.loginAdmin$ | async) {
    <mat-icon class="edit-button"
      (click)="onEdit()">
      construction
    </mat-icon>
    }
  </h6>
  <div class="content-holder">
    <div class="item-image-div">
      <img class="item-image"
        loading="lazy"
        [src]="item.imagePath"
        appDefaultLogo
        [alt]="item.title">
    </div>
    <div class="item-data desc">
      {{ item.description | translit }}
    </div>
  </div>
</div>
}