import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { HistoricalEvent } from '@app/services/historical-events.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-daily-event-card',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslitPipe],
  templateUrl: './daily-event-card.component.html',
  styleUrl: './daily-event-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyEventCardComponent {
  @Input({ required: true }) item!: HistoricalEvent;
  private router = inject(Router);
  public authService = inject(AuthService);

  onEdit() {
    const id = this.item.id;
    this.router.navigateByUrl(`admin/panel/event-form/${id}`);
  }
}
